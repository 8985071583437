.pill {
  padding: var(--spacing-0) var(--spacing-2);
  font: var(--copy-xs-medium);
  border-radius: var(--spacing-3);
}

.pillRed {
  background-color: var(--color-orange-100);
  color: var(--color-orange-800);
}

.pillGray {
  background-color: var(--color-blue-100);
  color: var(--color-gray-800);
}

.pillYellow {
  background-color: var(--color-yellow-300);
  color: var(--color-yellow-800);
}

.auditModal :global(.ant-modal-content) {
  max-width: 520px;
}

.auditModal.emptyModal :global(.ant-modal-content) {
  max-width: 432px;
}

.auditModal.successModal :global(.ant-modal-content) {
  max-width: 432px;
}

.auditModal :global(.ant-modal-body) {
  padding: var(--spacing-8) var(--spacing-8) var(--spacing-6)
}

.modalTitle {
  font: 500 18px/22px var(--font-family)
}

.confirmationText {
  font: var(--copy-s-regular);
  margin: var(--spacing-3) 0 var(--spacing-5) 0;
}

.infoCard {
  background-color: white;
  border: 1px solid var(--color-orange-100);
  border-radius: var(--spacing-2);
  box-shadow: 0px 1px 4px rgba(83, 83, 83, 0.08);
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-4);
}

.infoHeader {
  display: flex;
  align-items: center;
  font: var(--copy-s-medium);
  gap: var(--spacing-2)
}

.infoIconWrap {
  height: var(--spacing-8);
  width: var(--spacing-8);
  background: var(--color-blue-100);
  border-radius: var(--spacing-1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--spacing-1);
}

.infoValue {
  font: var(--copy-xs-medium);
  line-height: 18px;
  padding: 3px var(--spacing-3);
}

.divider {
  margin: var(--spacing-3) 0;
  color: var(--color-orange-100)
}

.infoBody {
  font: var(--copy-xs-regular);
  line-height: 18px;
}

.footerButtonGroup {
  display: flex;
  justify-content: flex-end;
  font: var(--copy-s-medium);
  margin-top: var(--spacing-6)
}

.footerButtonGroup.footerGroupLeft {
  justify-content: flex-start;
}

.dismissModalButton {
  border-radius: var(--spacing-1);
  background-color: var(--color-blue-500);
}

.continueButton {
  margin-right: var(--spacing-3)
}

.continueButton.continueButtonRight {
  margin-right: var(--spacing-3)
}