/* 24 column grid layout form */
.d-grid-24 {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  grid-gap: var(--spacing-xl);
}

.grid-start-1 {
  grid-column-start: 1;
}

.grid-1 {
  grid-column-end: span 1;
}

.grid-6 {
  grid-column-end: span 6;
}

.grid-8 {
  grid-column-end: span 8;
}

.grid-10 {
  grid-column-end: span 10;
}

.grid-12 {
  grid-column-end: span 12;
}

.grid-16 {
  grid-column-end: span 16;
}

.grid-20 {
  grid-column-end: span 20;
}

.grid-24 {
  grid-column-end: span 24;
}

@media (max-width: 1280px) {
  .grid-xl-1 {
    grid-column-end: span 1;
  }

  .grid-xl-6 {
    grid-column-end: span 6;
  }

  .grid-xl-8 {
    grid-column-end: span 8;
  }

  .grid-xl-10 {
    grid-column-end: span 10;
  }

  .grid-xl-12 {
    grid-column-end: span 12;
  }

  .grid-xl-16 {
    grid-column-end: span 16;
  }

  .grid-xl-20 {
    grid-column-end: span 20;
  }

  .grid-xl-24 {
    grid-column-end: span 24;
  }
}

/* grid lg */
@media (max-width: 992px) {
  .grid-lg-1 {
    grid-column-end: span 1;
  }

  .grid-lg-6 {
    grid-column-end: span 6;
  }

  .grid-lg-8 {
    grid-column-end: span 8;
  }

  .grid-lg-10 {
    grid-column-end: span 10;
  }

  .grid-lg-12 {
    grid-column-end: span 12;
  }

  .grid-lg-16 {
    grid-column-end: span 16;
  }

  .grid-lg-20 {
    grid-column-end: span 20;
  }

  .grid-lg-24 {
    grid-column-end: span 24;
  }
}

/* grid lg */
@media (max-width: 768px) {
  .grid-md-1 {
    grid-column-end: span 1;
  }

  .grid-md-6 {
    grid-column-end: span 6;
  }

  .grid-md-8 {
    grid-column-end: span 8;
  }

  .grid-md-10 {
    grid-column-end: span 10;
  }

  .grid-md-12 {
    grid-column-end: span 12;
  }

  .grid-md-16 {
    grid-column-end: span 16;
  }

  .grid-md-20 {
    grid-column-end: span 20;
  }

  .grid-md-24 {
    grid-column-end: span 24;
  }
}

/* grid sm */
@media (max-width: 640px) {
  .grid-sm-1 {
    grid-column-end: span 1;
  }

  .grid-sm-6 {
    grid-column-end: span 6;
  }

  .grid-sm-8 {
    grid-column-end: span 8;
  }

  .grid-sm-10 {
    grid-column-end: span 10;
  }

  .grid-sm-12 {
    grid-column-end: span 12;
  }

  .grid-sm-16 {
    grid-column-end: span 16;
  }

  .grid-sm-20 {
    grid-column-end: span 20;
  }

  .grid-sm-24 {
    grid-column-end: span 24;
  }
}
