/* common styles */
.requiredText {
  color: var(--red-5);
  font-size: 0.75rem;
  font-weight: 600;
}

.borderRed {
  border: 1px solid var(--red-6);
}

.labelText {
  color: var(--gray-10);
  display: flex;
  font-size: 0.875rem;
  margin-bottom: var(--spacing-s);
  align-items: flex-start;
  gap: 0.2rem;
}

.errorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error {
  color: var(--gray-7);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.errorIcon {
  color: var(--red-6);
  margin-right: var(--spacing-xxs);
}

.helperText {
  color: var(--gray-7);
  display: block;
  font-size: 0.75rem;
  margin-top: var(--spacing-s);
}

/* textarea */
.textareaWordCount {
  color: var(--gray-6);
  display: block;
  text-align: right;
}

/* list field */
.list {
  list-style: none;
  padding: 0;
  margin: var(--spacing-xl) 0 0 0;
}

.list li {
  color: var(--gray-8);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xxs);
}

.list svg {
  color: var(--gray-8);
}

/* phone field */
.phoneExtensionSelect {
  min-width: 75px;
}

/* select field */
.selectField :global(.ant-select-multiple .ant-select-selection-item) {
  font-size: 0.875rem;
  max-width: 7rem;
}

/* selection field */
.selectionField {
  position: relative;
}

.selectionField input {
  visibility: hidden;
  display: none;
}

.selectionField > label {
  border-radius: 2px;
  border: solid 1px var(--gray-5);
  color: var(--gray-10);
  cursor: pointer;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-m) 0 var(--spacing-xxl-6);
}

.selectionField > label :global(.anticon) {
  position: absolute;
  left: 1rem;
}

.selectionField .labelValue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selectionField > .selected {
  border-color: var(--blue-6);
  transition: border-color 500ms;
}

.selectionField > label svg {
  fill: var(--gray-7);
}

.selectionField > .selected svg {
  fill: var(--blue-6);
}

/* social */
.socialPrefixIcon {
  color: var(--blue-6);
}

.socialSuffixIcon {
  color: var(--gray-8);
  font-size: 14px;
}

.socialField:not(:last-of-type) {
  margin-bottom: var(--spacing-xl);
}

.socialField > :global(.ant-input-prefix) {
  margin-right: var(--spacing-s);
}

.ratingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingText {
  color: var(--gray-6);
  font-size: 0.875rem;
}

/* Tag Select */
.blocksSelection span {
  display: inline-block;
  margin-bottom: var(--spacing-m);
  cursor: pointer;
}

.blockSelect__button {
  padding: var(--spacing-xl) 0;
}
.visibilityHidden {
  visibility: hidden;
}

.activeBlock {
  border: 1px solid;
  padding: 5px;
}

.tagBlock {
  color: var(--gray-7);
  cursor: pointer;
  text-align: center;
  transition: all ease-in-out 0.2s;
}

.addLinkContainer {
  margin-bottom: var(--spacing-l);
}

.inlineSave {
  color: var(--blue-50);
  cursor: pointer;
}
