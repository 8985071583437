.modalDiv {
    border-radius: 1rem;
}

.modalDiv :global(.ant-modal-content){
    height: 90vh;
    display: flex;
    flex-direction: column;
}

.modalDiv :global(.ant-modal-title){
    font-size: 18px;
}

.modalDiv :global(.ant-modal-footer){
    visibility: hidden;
    height: 0;
}

.modalDiv :global(.ant-modal-header){
    padding: var(--spacing-8);
    padding-bottom: 0;
    border: 0;
}

.modalDiv :global(.ant-modal-body){
    border: 0;
    padding: var(--spacing-6) var(--spacing-8);
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc( 90vh - 54px );
}

.separation {
    border: 0;
    border-top: 1px solid var(--color-gray-200);
}

.getFieldComponent span span:nth-child(1) {
    display: none;
}

.getFieldComponent label span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-gray-700);
}

.getFieldComponent textarea {
    min-height: 76px !important;
    max-height: 200px !important;
}

.getFieldComponent div {
    color: var(--color-magenta-600);
}

.textArea {
    border-radius: 6px;
    padding: var(--spacing-3) var(--spacing-4) var(--spacing-4) var(--spacing-4);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Inter';
}

.refItemsLoaderWrap{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.refCheckButton {
    background-color: var(--color-blue-500) !important;
    border-radius: 79px;
    margin-bottom: var(--spacing-4);
    color: #FFFFFF;
    width: 160px;
    margin-top: var(--spacing-4);
}

.refCheckButton:hover {
    background-color: var(--color-blue-500) !important;
}

.refCheckButton:focus {
    background-color: var(--color-blue-500) !important;
}

.loaderIconContainer {
    display: flex;
    justify-content: center;
}

.loaderIcon {
    animation: spin 1s linear infinite;
    margin: var(--spacing-4);
}

.loaderText {
    text-align: center;
}

.loaderIconButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.loaderIconButton {
    transform: scale(0.5);
    background-color: var(--color-blue-500);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.refItemList {
    margin-top: var(--spacing-4);
    height: calc( 100% - 180px );
    overflow: auto;
}

.errorMsg {
    font: var(--copy-s-medium);
    margin-top: var(--spacing-4);
}
