@import '~antd/dist/antd.less';

body {
  color: var(--gray-9);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--gray-9);
}

@font-family: Inter, sans-serif;@primary-color: #2E16E6;@text-color:  #262626;@height-base: 40px;@height-lg: 64px;@height-sm: 24px;@zindex-message: 999999;