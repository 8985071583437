@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');
@import './variables.css';

/* css custom properties */
:root {
  --magenta-11: #fff5f9;
  --magenta-10: #520339;
  --magenta-9: #780650;
  --magenta-8: #9e1068;
  --magenta-7: #c41d7f;
  --magenta-6: #eb2f96;
  --magenta-4: #ff85c0;
  --magenta-5: #f759ab;
  --magenta-3: #ffadd2;
  --magenta-2: #ffd6e7;
  --magenta-1: #fff0f6;
  --purple-13: #d4bbf6;
  --purple-12: #5917b5;
  --purple-11: #f6f1fd;
  --purple-10: #120338;
  --purple-9: #22075e;
  --purple-8: #391085;
  --purple-7: #531dab;
  --purple-6: #722ed1;
  --purple-5: #9254de;
  --purple-4: #b37feb;
  --purple-3: #d3adf7;
  --purple-2: #efdbff;
  --purple-1: #f9f0ff;
  --blue-30: #a399ff;
  --geekblue-10: #030852;
  --geekblue-9: #061178;
  --geekblue-8: #10239e;
  --geekblue-6: #2f54eb;
  --geekblue-5: #597ef7;
  --geekblue-7: #1d39c4;
  --geekblue-4: #85a5ff;
  --geekblue-3: #adc6ff;
  --geekblue-2: #d6e4ff;
  --geekblue-1: #f0f5ff;
  --cyan-11: #ebfcff;
  --cyan-10: #002329;
  --cyan-9: #00474f;
  --cyan-8: #006d75;
  --cyan-7: #08979c;
  --cyan-6: #13c2c2;
  --cyan-5: #36cfc9;
  --cyan-4: #5cdbd3;
  --cyan-3: #87e8de;
  --cyan-2: #b5f5ec;
  --cyan-1: #e6fffb;
  --green-10: #092b00;
  --green-9: #135200;
  --green-8: #237804;
  --green-7: #389e0d;
  --green-6: #52c41a;
  --green-5: #73d13d;
  --green-4: #95de64;
  --green-3: #b7eb8f;
  --green-1: #f6ffed;
  --green-2: #d9f7be;
  --lime-10: #254000;
  --lime-9: #3f6600;
  --lime-8: #5b8c00;
  --lime-7: #7cb305;
  --lime-6: #a0d911;
  --lime-5: #bae637;
  --lime-4: #d3f261;
  --lime-3: #eaff8f;
  --lime-2: #f4ffb8;
  --lime-1: #fcffe6;
  --yellow-60: #ffbd00;
  --yellow-11: #f5edd6;
  --yellow-10: #614700;
  --yellow-9: #876800;
  --yellow-8: #ad8b00;
  --yellow-7: #d4b106;
  --yellow-6: #fadb14;
  --yellow-5: #ffec3d;
  --yellow-4: #fff566;
  --yellow-3: #fffb8f;
  --yellow-2: #ffffb8;
  --yellow-1: #feffe6;
  --gold-10: #332809;
  --gold-9: #664f12;
  --gold-8: #99781a;
  --gold-7: #cc9f23;
  --gold-6: #ffc72c;
  --gold-5: #ffd152;
  --gold-4: #ffdb78;
  --gold-3: #ffe59e;
  --gold-2: #ffefc4;
  --gold-1: #fff4d5;
  --orange-10: #612500;
  --orange-9: #873800;
  --orange-8: #ad4e00;
  --orange-7: #d46b08;
  --orange-6: #fa8c16;
  --orange-5: #ffa940;
  --orange-4: #ffc069;
  --orange-3: #ffd591;
  --orange-2: #ffe7ba;
  --orange-1: #fff7e6;
  --volcano-10: #610b00;
  --volcano-9: #871400;
  --volcano-8: #ad2102;
  --volcano-7: #d4380d;
  --volcano-6: #fa541c;
  --volcano-5: #ff7a45;
  --volcano-4: #ff9c6e;
  --volcano-3: #ffbb96;
  --volcano-2: #ffd8bf;
  --volcano-1: #fff2e8;
  --red-10: #5c0011;
  --red-9: #820014;
  --red-8: #a8071a;
  --red-7: #cf1322;
  --red-6: #f5222d;
  --red-5: #ff4d4f;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-1: #fff1f0;
  --blue-11: #f6f5ff;
  --blue-10: #09042e;
  --blue-9: #12095c;
  --blue-6: #2e16e6;
  --blue-7: #2512b8;
  --blue-8: #1c0d8a;
  --blue-5: #5440eb;
  --blue-4: #796aef;
  --blue-3: #9f94f3;
  --blue-2: #c5bef8;
  --blue-1: #eae8fc;
  --gray-800: #16142a;

  --gray-18: #f8f9fa;
  --gray-17: #67737e;
  --gray-16: #fbfaf6;
  --gray-15: #f2f3f7;
  --gray-14: #e4e2ed;
  --gray-13: #f4f1f5;
  --gray-12: #f6f6f6;
  --gray-11: #f9f6f9;
  --gray-10: #000;
  --gray-9: #262626;
  --gray-8: #595959;
  --gray-7: #8c8c8c;
  --gray-6: #bfbfbf;
  --gray-4: #e8e8e8;
  --gray-5: #d9d9d9;
  --gray-3: #f5f5f5;
  --gray-2: #fafafa;
  --gray-1: #fff;

  /* new color sets */
  --font-blue: #1600e5;
  /* prefer blue-50 */
  --active-blue: rgba(22, 0, 229, 0.05);
  /* #1600E5 with 50% opacity */
  --hover-background: #f8f8f8;
  --light-blue: #ede6ff;
  --border: #e9e2ec;
  --font-gray: #66667d;
  --error-red: #f25454;
  --active-background-blue: #f3f2fe;
  --background-gray: #fdfdfd;
  --dark-blue: #2e17e6;
  --disabled: #c5c5c9;
  --light-green: #8cf5d7;
  --dark-green: #138648;
  --magenta-60: #cc005a;
  --purple-70: #451f7a;
  --blue-70: #0c0080;
  --green-70: #1ab260;
  --gray-60: #f7f7f7;
  --gray: #8b8ba8;
  --gray-border: #e9ecef;
  --back-fill: #e5e5e5;
  --white: #ffffff;
  /* color set 20 */
  --blue-20: #e3e0ff;
  --magenta-20: #ffb3d4;
  --green-20: #bcf5d7;

  /* color set 40 */
  --blue-40: #7566ff;
  --yellow-40: #ffd766;
  --purple-40: #8c4ae8;
  --orange-40: #ff9d80;
  --cyan-40: #4ce6ff;
  --magenta-40: #ff4c9b;
  --green-40: #4de593;
  --gray-40: #c5ccd3;

  /* color set 50 */
  --blue-50: #1600e5;
  --yellow-50: #ffca33;
  --purple-50: #6f1de2;
  --orange-50: #ff6333;
  --cyan-50: #00dbff;
  --magenta-50: #ff0070;
  --green-50: #20df78;
  --gray-50: #9da6af;

  /* new set 10 */
  --new-cyan-10: #e5fbff;
  --new-blue-10: #e8e5ff;
  --new-yellow-10: #fbf9f4;

  /* new set 30 */
  --yellow-30: #ffe599;
  --gray-30: #dee2e6;

  /* set of 60 */
  --cyan-60: #00c5e5;
  --green-60: #1dc96c;

  /* set of 70 */
  --cyan-70: #00b0cc;
  --yellow-70: #cc9700;

  /* set of 80 */
  --cyan-80: #008499;
  --gray-80: #2e3338;
  --yellow-80: #997200;

  /* set of 90 */
  --gray-90: #171a1c;
  --yellow-90: #332600;

  --yellow-100: #cc9700;
  --gray-100: #464d53;

  /* spacing */
  --spacing-xxs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-s: 0.75rem;
  --spacing-m: 1rem;
  --spacing-l: 1.25rem;
  --spacing-xl: 1.5rem;
  --spacing-xxl: 1.75rem;
  --spacing-xxl-2: 2rem;
  --spacing-xxl-3: 2.25rem;
  --spacing-xxl-4: 2.5rem;
  --spacing-xxl-5: 2.75rem;
  --spacing-xxl-6: 3rem;
  --spacing-xxl-7: 3.25rem;
  --spacing-xxl-8: 3.5rem;
  --spacing-xxl-9: 3.75rem;
  --spacing-xxl-10: 4rem;
  --spacing-xxl-11: 4.25rem;

  /* width */
  --max-body-width: 1440px;
  --drawer-width: 536px;
}

/* spacing utilities */
.m-0 {
  margin: 0 !important;
}

/* width utilities */
.w-100 {
  width: 100%;
}

/* display utilities */
.block {
  display: block;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

/* other utilities */
.underline {
  text-decoration: underline;
}

/* custom scrollbar class */
.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--gray-3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--gray-5);
}

/* component utils */
.spinner-full {
  min-height: 80vh;
  width: 100%;
  display: grid !important;
  place-items: center;
}

.button-sm {
  height: 1.875rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

p {
  margin-bottom: 0;
}

/* Ant Design Overrides */
.ant-breadcrumb-link {
  text-transform: capitalize;
}

/* CKEditor Overrides */
.ck-editor__editable {
  min-height: 100px !important;
  max-height: 200px !important;
}

/* global components */
.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--gray-2);
}

.tab {
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);
  color: var(--gray-6);
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.tab:hover {
  color: unset;
}

.tab.active {
  color: var(--blue-6);
  border-bottom: 3px solid var(--blue-6);
  background-color: var(--blue-1);
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);
}

#beamer-element {
  cursor: pointer;
}

.customScrollBar {
  scrollbar-color: var(--border);
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overflow: overlay;
}

.customScrollBar::-webkit-scrollbar {
  width: 0;
}

.customScrollBar:hover::-webkit-scrollbar {
  width: 4px;
}

.customScrollBar::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.customScrollBar::-webkit-scrollbar-thumb {
  background-color: var(--gray-6);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
  visibility: hidden;
}

.customScrollBar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/* Pills */

.pill {
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 12px;
}

.pill-green {
  background-color: #e9fcf2;
  color: var(--green-70);
}

.pill-red {
  background-color: #ffefeb;
  color: #e53600;
}

.pill-magenta {
  background-color: var(--magenta-1);
  color: #b2004e;
}

.pill-cyan {
  background-color: #e5fbff;
  color: #00b0cc;
}

.pill-purple {
  background-color: #e8e5ff;
  color: #0c0080;
}

.pill-yellow {
  background-color: #f5edd6;
  color: #cc9700;
}

.pill-gray {
  background-color: #e9ecef;
  color: #464d53;
}

/* antd overrides */
.ant-btn > .ant-btn-loading-icon .anticon {
  /* spin indicator wasn't center aligned */
  padding-right: 0 !important;
  margin-right: 8px;
}

.remirror-floating-popover {
  z-index: 10 !important;
  opacity: 1;
}

.remirror-mention-atom {
  background-color: lightgrey !important;
  border-radius: 10px !important;
}

.form-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-flex > .ant-select {
  width: 50%;
}

.switch-left {
  width: 100%;
  margin: auto;
}

.rbc-label,
.rbc-time-header-gutter {
  display: none;
}

.rbc-time-content {
  display: none !important;
}

.rbc-time-header-content {
  border: none !important;
}
