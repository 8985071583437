:root {
  /* Colors */
  --color-blue-100: #F6F5FF;
  --color-blue-200: #E3E0FF;
  --color-blue-300: #A399FF;
  --color-blue-400: #7566FF;
  --color-blue-500: #1600E5;
  --color-blue-600: #1100B3;
  --color-blue-700: #0C0080;
  --color-blue-800: #0A0066;
  --color-blue-900: #050033;

  --color-yellow-100: #FBF9F4;
  --color-yellow-200: #F5EDD6;
  --color-yellow-300: #FFE599;
  --color-yellow-400: #FFD766;
  --color-yellow-500: #FFCA33;
  --color-yellow-600: #FFBD00;
  --color-yellow-700: #CC9700;
  --color-yellow-800: #997200;
  --color-yellow-900: #332600;

  --color-purple-100: #F6F1FD;
  --color-purple-200: #D4BBF6;
  --color-purple-300: #B78EF0;
  --color-purple-400: #8C4AE8;
  --color-purple-500: #6F1DE2;
  --color-purple-600: #5917B5;
  --color-purple-700: #451F7A;
  --color-purple-800: #391966;
  --color-purple-900: #170A29;

  --color-orange-100: #FFEFEB;
  --color-orange-200: #FFC4B3;
  --color-orange-300: #FFB199;
  --color-orange-400: #FF9D80;
  --color-orange-500: #FF6333;
  --color-orange-600: #FF3C00;
  --color-orange-700: #E53600;
  --color-orange-800: #B22A00;
  --color-orange-900: #330C00;

  --color-cyan-100: #EBFCFF;
  --color-cyan-200: #B3F4FF;
  --color-cyan-300: #80EDFF;
  --color-cyan-400: #4CE6FF;
  --color-cyan-500: #00DBFF;
  --color-cyan-600: #00C5E5;
  --color-cyan-700: #00B0CC;
  --color-cyan-800: #008499;
  --color-cyan-900: #002C33;

  --color-magenta-100: #FFF5F9;
  --color-magenta-200: #FFCCE2;
  --color-magenta-300: #FF80B8;
  --color-magenta-400: #FF4C9B;
  --color-magenta-500: #FF0070;
  --color-magenta-600: #CC005A;
  --color-magenta-700: #B2004E;
  --color-magenta-800: #990043;
  --color-magenta-900: #3D001B;

  --color-green-100: #F2FDF7;
  --color-green-200: #BCF5D7;
  --color-green-300: #8FEFBC;
  --color-green-400: #4DE593;
  --color-green-500: #20DF78;
  --color-green-600: #1DC96C;
  --color-green-700: #1AB260;
  --color-green-800: #138648;
  --color-green-900: #062D18;

  --color-gray-100: #F8F9FA;
  --color-gray-200: #E9ECEF;
  --color-gray-300: #DEE2E6;
  --color-gray-400: #C5CCD3;
  --color-gray-500: #9DA6AF;
  --color-gray-600: #67737E;
  --color-gray-700: #464D53;
  --color-gray-800: #2E3338;
  --color-gray-900: #171A1C;

  /* Shadows */
  --shadow-1: 0 2px 8px 0 rgba(83, 83, 83, 0.1);
  --shadow-2: 0 2px 10px 0 rgba(83, 83, 83, 0.2);
  --shadow-3: 0 2px 15px 0 rgba(83, 83, 83, 0.2);

  /* Spacing */
  --spacing-0: 2px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;

  /* Typography */
  --font-family: Inter, sans-serif;

  --copy-xs-regular: 12px/16px var(--font-family);
  --copy-xs-medium: 500 12px/16px var(--font-family);
  --copy-xs-semi-bold: 600 12px/16px var(--font-family);
  --copy-xs--bold: 700 12px/16px var(--font-family);

  --copy-s-regular: 14px/20px var(--font-family);
  --copy-s-medium: 500 14px/20px var(--font-family);
  --copy-s-semi-bold: 600 14px/20px var(--font-family);
  --copy-s-bold: 700 14px/20px var(--font-family);

  --copy-base-regular: 16px/24px var(--font-family);
  --copy-base-medium: 500 16px/24px var(--font-family);
  --copy-base-semi-bold: 600 16px/24px var(--font-family);
  --copy-base-bold: 700 16px/24px var(--font-family);
}