.wrap {
  padding: var(--spacing-4) var(--spacing-4) 0;
}

.wrapInside {
  box-shadow: 0px 1px 4px rgba(83, 83, 83, 0.08);
  background: var(--gray-1);
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-4);
  cursor: pointer;
  gap: var(--spacing-2);
}

.fieldIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 6px;
}

.fieldIcon {
  color: var(--font-gray);
}

.fieldTextWrap {
  display: flex;
  flex-direction: column;
  padding-left: var(--spacing-1);
}

.fieldTextDetails {
  color: var(--gray-9);
  font-size: 0.88rem;
  font-weight: 500;
}

.expandIcon {
  width: 1.2rem;
  color: var(--font-gray);
  margin-left: auto;
}

.viewSuggestionsPill {
  padding: var(--spacing-1) var(--spacing-3);
  font: var(--copy-xs-medium);
  line-height: 18px;
  border-radius: 34px;
}

.header {
  display: flex;
  background: var(--gray-1);
  align-items: center;
  padding: var(--spacing-2) var(--spacing-4);
  box-shadow: 0px 2px 8px rgb(83 83 83 / 10%);
  position: sticky;
  top: 0;
  z-index: 100;
  height: 52px;
}

.headerText {
  flex: 1;
  color: var(--gray-9);
  font-size: 0.88rem;
  font-weight: 500;
  padding-left: var(--spacing-4);
}

.closeIcon {
  width: 1.2rem;
  color: var(--font-gray);
  cursor: pointer;
}

.infoBodyWrap {
  padding: 0 var(--spacing-4) var(--spacing-4) !important;
  height: calc( 100% - 68px);
  overflow: auto;
}

.infoBodyInside {
  overflow: hidden;
  margin-top: var(--spacing-4);
}

.pill {
  padding: var(--spacing-0) var(--spacing-2);
  font: var(--copy-xs-medium);
  border-radius: var(--spacing-3);
}

.pillRed {
  background-color: var(--color-orange-100);
  color: var(--color-orange-800);
}

.pillGray {
  background-color: var(--color-blue-100);
  color: var(--color-gray-800);
}

.pillBlue {
  background-color: var(--color-blue-100);
  color: var(--color-blue-500);
}

.plagarismCard {
  background-color: white;
  border: 1px solid var(--color-orange-100);
  border-radius: var(--spacing-2);
  box-shadow: 0px 1px 4px rgba(83, 83, 83, 0.08);
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-1) var(--spacing-5);
}

.plagarismHeader {
  font: var(--copy-s-medium);
  height: var(--spacing-6);
  display: flex;
  align-items: center;
}

.plagarismPercent {
  margin-left: var(--spacing-2);
}

.plagarismBody {
  margin: var(--spacing-3) 0 var(--spacing-4) 0;
  font: var(--copy-s-regular);
}

.refreshButton {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-5);
  padding: 6px var(--spacing-3);
  border-radius: 80px;
  background-color: var(--color-blue-500);
}

.refreshButtonText {
  padding-left: var(--spacing-2);
  font: var(--copy-s-medium);
}

.resultCard {
  background-color: white;
  border: 1px solid var(--color-gray-100);
  border-radius: var(--spacing-2);
  box-shadow: 0px 1px 4px rgba(83, 83, 83, 0.08);
  margin-bottom: var(--spacing-4);
}

.resultHeader {
  padding: var(--spacing-1) 0 var(--spacing-1) var(--spacing-5);
  border-radius: var(--spacing-2) var(--spacing-2) 0 0;
}

.resultBody {
  padding: var(--spacing-4) var(--spacing-5);
  font: var(--copy-s-regular);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.resultTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.resultLink {
  font: var(--copy-xs-regular);
  line-height: 18px;
}

.confirmationModal :global(.ant-modal-content) {
  max-width: 432px;
}

.confirmationModal :global(.ant-modal-body) {
  padding: var(--spacing-8);
}

.confirmationTitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.confirmationText {
  font: var(--copy-s-regular);
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-6);
}

.confirmationButtonGroup {
  display: flex;
  font: var(--copy-s-medium);
}

.continueButton {
  border-radius: var(--spacing-1);
  background-color: var(--color-blue-500);
}

.dismissModalButton {
  margin-left: var(--spacing-2);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 64px;
}

.loaderText {
  font: var(--copy-s-regular);
  text-align: center;
  margin-top: var(--spacing-4);
}

.empty {
  width: 100%;
  height: 80%;
  padding: 0 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customScrollBar {
  padding-right: 0;
  scrollbar-color: var(--border);
  scrollbar-width: thin;
}
.customScrollBar::-webkit-scrollbar {
  width: 4px;
}
.customScrollBar::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
  cursor: pointer;
}
.customScrollBar::-webkit-scrollbar-thumb {
  background-color: var(--gray-6); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
  cursor: pointer;
}